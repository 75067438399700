import Highcharts, { ExportingOptions } from "highcharts/highstock";
import exportingData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";

import { titleHtmlText } from "./titleHtmlText";

const preprocessStrings = (s?: string): string | undefined => {
  return s
    ?.replace(/(\r\n|\n|\r)/gm, "")
    .replace(/\s+/g, " ")
    .trim();
};

export const ExportingConfigration = (
  filename?: string,
  subtitle?: string,
  dateString?: string
): ExportingOptions => {
  Highcharts.SVGRenderer.prototype.symbols.download = function (
    x: number,
    y: number,
    w: number,
    h: number
  ) {
    const path = [
      // Arrow stem
      "M",
      x + w * 0.5,
      y,
      "L",
      x + w * 0.5,
      y + h * 0.5,
      // Arrow head
      "M",
      x + w * 0.2,
      y + h * 0.3,
      "L",
      x + w * 0.5,
      y + h * 0.5,
      "L",
      x + w * 0.8,
      y + h * 0.3,
      // Box
      "M",
      x,
      y + h * 0.8,
      "L",
      x + w,
      y + h * 0.8
    ];
    return path;
  };
  exporting(Highcharts);
  exportingData(Highcharts);

  Highcharts.setOptions({ lang: { contextButtonTitle: "Downloads menu" } });
  const _filename = preprocessStrings(filename);
  return {
    filename: _filename,
    allowHTML: true,
    chartOptions: {
      title: {
        text: titleHtmlText({
          title: _filename ?? "",
          subtitle: preprocessStrings(subtitle),
          dateString: preprocessStrings(dateString)
        }),
        useHTML: true,
        align: "left"
      }
    },
    buttons: {
      contextButton: {
        menuItems: [
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadSVG",
          "separator",
          "downloadCSV"
        ],
        symbol: "download",
        symbolFill: "#245194",
        // The symbol stroke property does exist in the high charts documentation, but
        // ts lint complains that it doesn't exist, that's why the following line is ignored:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        symbolStroke: "#245194"
      }
    },
    csv: {
      itemDelimiter: ", "
    }
  };
};
