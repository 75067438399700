"use client";

import { PropsWithChildren, ReactNode } from "react";
import { Paper, Table, TableContainer, TableContainerProps, TableProps } from "@mui/material";
import isNil from "lodash/isNil";

import { DataTableBaseRow, DataTableSortingProps } from "./types";

import { InfoIconVariants } from "../InfoIcon";
import { DataTableBody, DataTableBodyProps } from "./DataTableBody";
import { DataTableHead } from "./DataTableHead";

export interface DataTableProps<T extends DataTableBaseRow> extends DataTableBodyProps<T> {
  additionalTableHeaderRow?: ReactNode;
  id?: string;
  infoIconVariant?: InfoIconVariants;
  maxHeight?: number | string | { xs?: string | number; md?: string | number };
  removeBorders?: boolean;
  renderHead?: (
    sorting: DataTableSortingProps | undefined,
    columns: DataTableBodyProps<T>["columns"]
  ) => React.ReactNode;
  rowHover?: boolean;
  size?: TableProps["size"];
  sorting?: DataTableSortingProps;
  sxForContainer?: TableContainerProps["sx"];
}

const DataTableHeadWrapper = <T extends DataTableBaseRow>({
  sorting,
  columns,
  renderHead,
  additionalTableHeaderRow,
  infoIconVariant
}: {
  sorting?: DataTableSortingProps | undefined;
  columns: DataTableBodyProps<T>["columns"];
  additionalTableHeaderRow?: ReactNode;
  infoIconVariant?: InfoIconVariants;
  renderHead?: (
    sorting: DataTableSortingProps | undefined,
    columns: DataTableBodyProps<T>["columns"]
  ) => React.ReactNode;
}) => {
  if (isNil(renderHead)) {
    return (
      <DataTableHead<T>
        sorting={sorting}
        columns={columns}
        infoIconVariant={infoIconVariant}
        additionalTableHeaderRow={additionalTableHeaderRow}
      />
    );
  }
  return <>{renderHead(sorting, columns)}</>;
};

export const DataTable = <T extends DataTableBaseRow>({
  additionalTableHeaderRow,
  columns,
  data,
  id,
  infoIconVariant,
  maxHeight,
  onRowClick,
  removeBorders,
  renderHead: renderHeadProp,
  rowHover = false,
  selectedRowId,
  size,
  sorting,
  sxForContainer = {}
}: PropsWithChildren<DataTableProps<T>>) => {
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{ maxHeight, border: removeBorders === true ? "none" : undefined, ...sxForContainer }}
      data-testid={id ?? "data-table"}
    >
      <Table size={size} stickyHeader={!!maxHeight}>
        <DataTableHeadWrapper
          sorting={sorting}
          columns={columns}
          renderHead={renderHeadProp}
          additionalTableHeaderRow={additionalTableHeaderRow}
          infoIconVariant={infoIconVariant}
        />
        <DataTableBody<T>
          data={data}
          columns={columns}
          onRowClick={onRowClick}
          selectedRowId={selectedRowId}
          rowHover={rowHover}
        />
      </Table>
    </TableContainer>
  );
};
