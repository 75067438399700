interface titleHtmlTextProps {
  title: string;
  subtitle?: string;
  dateString?: string;
}

export const titleHtmlText = ({ title, subtitle, dateString }: titleHtmlTextProps) => {
  return `
    <div style="
      display: flex;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 12px;
    ">
      <p style="
        font-size: 16px;
        font-weight: 700;
        font-family: Open Sans,sans-serif;
        margin: 0;
        padding: 0;
      ">${title} ${dateString ? `(${dateString ?? ""})` : ""}</p>
      ${
        subtitle !== undefined
          ? `
        <p style="
          font-family: Open Sans,sans-serif;
          font-weight: 400;
          padding: 0;
          padding-bottom: 2px;
          margin: 0;
          margin-bottom: 2px;
          font-size: 12px;
          line-height: 16px;
          color: #757575;
        ">${subtitle}</p>
      `
          : ""
      }
    </div>
  `;
};
