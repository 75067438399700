import { useMemo } from "react";

import { ChartSeriesOptions, RawChartSeries } from "../Chart/types";

const useSeriesInfo = (
  series: ChartSeriesOptions[],
  rawSeries?: RawChartSeries[]
): [number, number] => {
  return useMemo(() => {
    const lengths: number[] = [];
    const maxValues: number[] = [];
    if (rawSeries) {
      rawSeries.forEach(({ values = [] }) => {
        lengths.push(values.length);
        maxValues.push(Math.max(...(values.filter((v) => v) as number[])));
      });
      return [Math.max(...lengths), Math.max(...maxValues)];
    }
    series.forEach(({ data = [] }) => {
      lengths.push(data.length);
      const values = data.map((d) => {
        if (!d) return 0;
        if (typeof d === "object") {
          if ((d?.length ?? 0) > 1) {
            return (d as number[])[1] || 0;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return (d as unknown as any)?.y ?? 0;
        }
        if (typeof d === "number") {
          return d;
        }
        return 0;
      });
      maxValues.push(Math.max(...values));
    });
    return [Math.max(...lengths), Math.max(...maxValues)];
  }, [series, rawSeries]);
};

export default useSeriesInfo;
