import { DEFAULT_MARGIN_SMALL_SCREEN, SITE_HEADER_HEIGHT } from "layout/configuration";

interface Params {
  element: Element;
  container?: Element;
  additionalOffset?: number;
}

export const scrollToElementWithHeaderOffset = ({
  element,
  container = document.documentElement,
  additionalOffset = 0
}: Params) => {
  if (!element) return;
  const yOffset = SITE_HEADER_HEIGHT + 40 + DEFAULT_MARGIN_SMALL_SCREEN + additionalOffset;
  const top = element.getBoundingClientRect().top + window.pageYOffset - yOffset;
  container.scrollTo({ top, behavior: "smooth" });
};
