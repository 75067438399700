import { useMemo } from "react";
import { granularityTimeScaleLookup } from "common/components/charts/highchartsConfig";

import { ChartProps } from "common/components/charts/Chart/types";
import { TimeScale } from "common/components/charts/types";
import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { QUARTERS_AS_ABBREVIATED_MONTH_RANGES, QUARTERS_AS_MONTH_RANGES } from "common/util/date";

export const axisNudgingElement = (value: string | number) =>
  `<span style="position: relative; left: 25px;">${value}</span>`;

type UseTimeScale = {
  timeScale?: TimeScale;
  granularity?: MhcTimeSeriesGranularityEnum;
};

export const useTimeScale = ({ timeScale, granularity }: UseTimeScale) => {
  const _timeScale = useMemo((): TimeScale | undefined => {
    if (timeScale === undefined && granularity !== undefined) {
      return granularityTimeScaleLookup[granularity];
    } else if (timeScale === undefined) {
      return "yearly";
    }
    return timeScale;
  }, [timeScale, granularity]);
  return _timeScale;
};

type UpdateOptionsForQuarterlyData = Pick<
  ChartProps,
  "xAxisEnhancements" | "overridePlotOptions" | "xAxisOptions"
>;
export const updateOptionsForQuarterlyData = ({
  xAxisEnhancements = {},
  xAxisOptions = {},
  overridePlotOptions = {}
}: UpdateOptionsForQuarterlyData) => {
  xAxisEnhancements.xCategories = QUARTERS_AS_ABBREVIATED_MONTH_RANGES;
  xAxisEnhancements.xCategoriesReadableDict = QUARTERS_AS_ABBREVIATED_MONTH_RANGES.reduce(
    (acc: Record<string, string>, m, i) => {
      acc ||= {};
      acc[m] = QUARTERS_AS_MONTH_RANGES[i] as string;
      return acc;
    },
    {}
  );
  xAxisEnhancements.type = "category";
  xAxisOptions.minPadding = 0;
  xAxisOptions.maxPadding = 0;
  xAxisOptions.endOnTick = true;
  xAxisOptions.startOnTick = true;
  xAxisOptions.tickmarkPlacement = "on";
  xAxisOptions.labels = {
    useHTML: true,
    allowOverlap: false,
    formatter: ({ value, pos }) => (pos === 0 ? axisNudgingElement(value) : String(value))
  };
  overridePlotOptions.series = {
    pointPlacement: "on"
  };
};
