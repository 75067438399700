import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

export const rangeSelectorButtons = (granularity?: MhcTimeSeriesGranularityEnum) => {
  if (granularity === undefined || granularity === MhcTimeSeriesGranularityEnum.FiveYearWindow) {
    return [];
  }
  let buttons: Highcharts.RangeSelectorButtonsOptions[] = [];
  switch (granularity) {
    case MhcTimeSeriesGranularityEnum.Day:
      buttons = [
        { type: "month", count: 1, text: "1m" },
        { type: "month", count: 2, text: "2m" },
        { type: "month", count: 3, text: "3m" }
      ];
      break;
    case MhcTimeSeriesGranularityEnum.Month:
      buttons = [
        { type: "month", count: 6, text: "6m" },
        { type: "month", count: 12, text: "12m" },
        { type: "month", count: 24, text: "24m" }
      ];
      break;
    case MhcTimeSeriesGranularityEnum.Week:
      buttons = [
        { type: "month", count: 2, text: "2m" },
        { type: "month", count: 4, text: "4m" },
        { type: "month", count: 6, text: "6m" }
      ];
      break;
    case MhcTimeSeriesGranularityEnum.Year:
      buttons = [
        { type: "year", count: 5, text: "5y" },
        { type: "year", count: 10, text: "10y" },
        { type: "year", count: 15, text: "15y" }
      ];
      break;
    default:
      buttons = [];
  }
  buttons.push({ type: "all", text: "All" });
  return buttons;
};

export const rangeSelectorInputDateFormat = (granularity?: MhcTimeSeriesGranularityEnum) => {
  if (
    granularity === undefined ||
    granularity === MhcTimeSeriesGranularityEnum.Year ||
    granularity === MhcTimeSeriesGranularityEnum.FiveYearWindow
  ) {
    return "%Y";
  }
  return "%b %e, %Y";
};
