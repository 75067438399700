import { useMemo } from "react";
import isNil from "lodash/isNil";
import { NavigatorOptions, RangeSelectorOptions, ScrollbarOptions } from "highcharts";

import { ChartProps } from "../Chart/types";

import { rangeSelectorButtons, rangeSelectorInputDateFormat } from "./rangeSelector";

type UseChartDateControls = Pick<
  ChartProps,
  | "granularity"
  | "overrideRangeSelectorButtons"
  | "useNavigator"
  | "useRangeSelector"
  | "useRangeSelectorButtons"
  | "useScrollbar"
> & {
  pointCountToEnableZoom: number;
  seriesPointCount: number;
};

const useChartDateControls = ({
  granularity,
  overrideRangeSelectorButtons,
  pointCountToEnableZoom,
  seriesPointCount,
  useNavigator,
  useRangeSelector,
  useRangeSelectorButtons,
  useScrollbar
}: UseChartDateControls) => {
  const controls = useMemo(() => {
    let isRangeSelectorEnabled = useRangeSelector;
    let isNavigatorEnabled = useNavigator;
    let isScrollBarEnabled = useScrollbar;
    let areRangeSelectorButtonsEnabled = useRangeSelectorButtons;

    if (seriesPointCount > pointCountToEnableZoom) {
      isRangeSelectorEnabled = true;
      areRangeSelectorButtonsEnabled = true;
      isNavigatorEnabled = true;
      isScrollBarEnabled = true;
    }

    const buttons =
      areRangeSelectorButtonsEnabled === true
        ? !isNil(overrideRangeSelectorButtons)
          ? overrideRangeSelectorButtons
          : rangeSelectorButtons(granularity)
        : [];

    return {
      rangeSelector: {
        enabled: isRangeSelectorEnabled,
        ...(isRangeSelectorEnabled
          ? {
              inputEnabled: true,
              selected: undefined,
              allButtonsEnabled: true,
              inputDateFormat: rangeSelectorInputDateFormat(granularity),
              buttons
            }
          : {})
      } as RangeSelectorOptions,
      navigator: {
        enabled: isNavigatorEnabled
      } as NavigatorOptions,
      scrollbar: {
        enabled: isScrollBarEnabled
      } as ScrollbarOptions
    };
  }, [
    granularity,
    overrideRangeSelectorButtons,
    pointCountToEnableZoom,
    seriesPointCount,
    useNavigator,
    useRangeSelector,
    useRangeSelectorButtons,
    useScrollbar
  ]);

  return controls;
};

export default useChartDateControls;
